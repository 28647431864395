import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 720.000000 744.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,744.000000) scale(0.100000,-0.100000)">


<path d="M0 7370 l0 -70 3600 0 3600 0 0 70 0 70 -3600 0 -3600 0 0 -70z"/>
<path d="M2375 4120 c0 -201 1 -283 2 -182 2 100 2 264 0 365 -1 100 -2 18 -2
-183z"/>
<path d="M2391 4450 c0 -29 1 -32 10 -17 7 11 7 23 0 35 -9 14 -10 11 -10 -18z"/>
<path d="M4395 4445 c2 -22 9 -41 15 -43 5 -2 10 -12 10 -23 0 -11 5 -28 12
-39 10 -15 10 -27 0 -54 -13 -34 -8 -56 41 -176 6 -14 11 -30 12 -36 2 -6 6
-21 9 -32 10 -29 -5 -34 -104 -33 -49 1 -91 -2 -94 -7 -3 -5 21 -7 52 -6 55 2
56 2 12 -5 l-45 -7 40 -6 c34 -4 33 -5 -10 -3 -27 1 -56 4 -64 6 -7 2 -16 -2
-18 -8 -4 -10 25 -13 125 -13 103 0 132 -3 142 -15 7 -8 9 -15 4 -15 -4 0 -3
-5 4 -12 7 -7 12 -19 12 -27 0 -9 7 -25 16 -36 13 -18 14 -17 8 15 -4 19 -11
41 -17 47 -5 7 -7 20 -3 28 3 9 0 15 -9 15 -8 0 -15 8 -15 18 0 12 -3 13 -8 5
-6 -9 -11 -6 -20 8 -12 19 -11 20 7 14 24 -7 28 7 6 25 -17 14 -20 40 -5 40 6
0 10 -9 10 -20 0 -18 10 -25 33 -21 4 0 7 -3 7 -9 0 -5 -4 -10 -10 -10 -5 0
-7 -7 -4 -15 4 -8 10 -13 14 -10 8 5 24 -51 21 -73 -1 -6 2 -28 8 -47 6 -24 6
-35 -1 -35 -6 0 -7 -11 -3 -27 8 -35 14 -40 45 -45 21 -3 25 1 23 22 -2 14
-10 45 -20 70 -14 38 -17 41 -25 25 -6 -16 -7 -14 -4 9 2 18 -10 65 -31 120
-19 50 -47 126 -62 168 -15 43 -31 75 -35 70 -5 -4 -7 0 -5 8 1 8 0 22 -3 30
-3 8 -13 38 -23 65 -18 55 -27 78 -44 115 -10 23 -11 21 -6 -15z m81 -230 c8
-5 15 -23 16 -40 1 -16 5 -32 8 -35 15 -15 40 -70 30 -70 -6 0 -14 8 -17 18
-4 9 -15 26 -25 37 -10 10 -23 35 -28 55 -6 19 -14 41 -19 49 -6 9 -5 19 2 26
10 10 13 8 15 -8 2 -12 10 -26 18 -32z m74 -165 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-62 -75 c-3 -3 -9 2 -12 12
-6 14 -5 15 5 6 7 -7 10 -15 7 -18z m142 -146 c0 -6 -4 -7 -10 -4 -5 3 -10 11
-10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z"/>
<path d="M1625 4458 c-39 -14 -85 -46 -85 -60 0 -6 -7 -16 -16 -23 -12 -8 -17
-27 -16 -66 0 -78 2 -90 13 -84 12 8 12 62 -1 70 -5 3 -10 11 -10 16 0 6 5 7
10 4 6 -4 10 -3 9 2 -5 28 6 50 46 89 24 24 48 44 54 44 5 0 18 3 28 6 10 4 1
-6 -19 -20 -21 -15 -38 -31 -38 -36 0 -6 -7 -10 -15 -10 -21 0 -34 -31 -39
-96 -2 -33 -2 -49 1 -36 2 12 9 20 14 17 5 -4 9 12 9 35 0 34 6 47 39 79 21
21 46 41 55 44 19 7 22 37 4 36 -7 0 -26 -5 -43 -11z"/>
<path d="M1749 4459 c35 -4 67 -12 70 -17 3 -4 15 -8 26 -9 11 0 26 -2 34 -4
7 -2 15 1 17 7 6 15 -86 34 -154 33 l-57 -2 64 -8z"/>
<path d="M3240 4453 c-21 -9 -30 -17 -22 -20 8 -3 19 0 25 6 6 6 25 11 42 12
16 0 39 4 50 9 16 7 11 9 -20 9 -22 0 -56 -7 -75 -16z"/>
<path d="M3383 4459 c43 -17 21 -30 -46 -29 -79 2 -85 1 -70 -8 6 -4 50 -7 97
-7 47 1 86 -3 86 -7 0 -18 28 -6 34 14 8 32 -3 39 -70 44 -50 3 -55 2 -31 -7z
m82 -19 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16
-10z"/>
<path d="M5125 4461 l-30 -6 0 -350 -1 -350 6 325 5 325 3 -321 c2 -288 4
-323 18 -328 9 -4 18 -5 20 -3 2 2 6 233 5 290 -1 9 -8 17 -16 17 -8 0 -15 7
-15 15 0 8 5 15 10 15 6 0 10 -4 10 -10 0 -5 4 -10 8 -10 5 0 9 7 9 15 0 8 -9
15 -19 15 -13 0 -18 7 -18 25 0 15 6 25 14 25 12 0 15 24 18 125 2 69 0 125
-4 125 -5 0 -8 -54 -8 -120 0 -74 -4 -120 -10 -120 -12 0 -2 230 11 244 15 17
142 19 181 3 32 -14 37 -14 42 0 9 22 8 23 -26 16 -23 -5 -29 -3 -23 6 5 10
-3 11 -36 7 -64 -8 -188 -15 -161 -9 12 2 22 8 22 13 0 4 24 8 53 8 62 0 115
6 124 15 8 9 -151 7 -192 -2z m15 -421 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10
3 6 8 10 11 10 2 0 4 -4 4 -10z m0 -161 c0 -77 -4 -118 -10 -114 -14 9 -8 235
6 235 2 0 4 -54 4 -121z"/>
<path d="M2120 4450 c19 -4 27 -8 18 -9 -10 0 -18 -8 -18 -16 0 -18 16 -20 23
-2 3 6 5 -122 5 -286 0 -164 -2 -296 -5 -294 -2 3 -5 129 -5 281 0 167 -3 274
-9 271 -23 -15 -3 -635 21 -635 3 0 8 158 9 350 l3 350 -39 -1 -38 -2 35 -7z"/>
<path d="M2815 4451 c11 -5 23 -7 27 -5 4 3 6 -96 5 -218 l-1 -223 -3 208 c-3
169 -6 207 -17 207 -8 0 -17 6 -19 13 -3 7 -6 5 -6 -5 -1 -10 4 -18 9 -18 6 0
10 -74 10 -203 0 -163 3 -207 15 -223 14 -18 22 -104 9 -91 -17 17 -27 111
-33 302 l-7 210 -2 -193 c-1 -142 2 -213 14 -268 8 -42 13 -81 10 -87 -2 -7
-5 -2 -5 11 -1 12 -6 21 -12 20 -10 -3 -49 52 -66 92 -9 23 -75 115 -85 118
-4 2 -8 8 -8 14 0 6 -26 45 -57 87 -119 159 -139 187 -156 215 l-19 28 -14
-27 c-11 -22 -14 -91 -14 -341 0 -313 0 -314 21 -314 l21 0 -4 290 -4 290 23
0 c13 0 23 -4 23 -10 0 -5 -4 -10 -10 -10 -5 0 -10 -5 -10 -12 0 -6 9 -3 20 7
13 12 20 14 20 5 0 -6 14 -30 32 -52 50 -64 77 -106 70 -112 -8 -9 -24 -7 -18
2 2 4 -2 15 -9 25 -12 16 -13 16 -19 1 -7 -19 13 -52 106 -176 32 -43 58 -80
58 -82 0 -8 122 -170 134 -178 11 -7 26 9 26 30 0 18 -18 14 -23 -5 -4 -14 -5
-13 -6 3 0 12 5 24 12 27 9 3 13 27 12 78 -1 41 -2 188 -3 327 l-2 252 -32 -1
c-27 0 -29 -2 -13 -8z m-385 -45 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10
25 14 25 6z m7 -38 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-30
-95 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z m10 -70 c-3 -10
-5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m213 -78 c0 -8 -2 -15 -4 -15
-2 0 -6 7 -10 15 -3 8 -1 15 4 15 6 0 10 -7 10 -15z m-22 -15 c7 -11 9 -20 4
-20 -4 0 -13 9 -20 20 -7 11 -9 20 -4 20 4 0 13 -9 20 -20z m38 -36 c-4 -11
-1 -14 12 -10 11 4 22 -2 30 -14 7 -11 8 -20 3 -20 -5 0 -11 6 -14 13 -2 6 -7
9 -11 5 -4 -3 -2 -16 4 -28 9 -17 14 -19 26 -9 11 9 14 8 14 -5 0 -10 5 -14
10 -11 6 3 10 -3 10 -14 0 -12 0 -24 1 -28 0 -4 -4 -9 -10 -11 -6 -2 -11 4
-11 13 0 10 -11 26 -25 37 -40 31 -70 98 -44 98 6 0 8 -7 5 -16z m-229 -226
c-1 -46 -3 -11 -3 77 0 88 2 125 3 83 2 -42 2 -114 0 -160z m343 68 c0 -7 11
-23 25 -36 14 -13 25 -32 25 -43 0 -19 1 -19 20 3 16 18 20 20 20 7 0 -10 -4
-17 -10 -17 -5 0 -10 -10 -10 -21 0 -21 -1 -21 -20 4 -11 14 -20 28 -20 32 0
3 -11 21 -25 39 -25 33 -34 63 -15 51 6 -3 10 -12 10 -19z"/>
<path d="M3648 4388 c-4 -172 -3 -353 3 -493 4 -117 6 -74 9 195 1 190 2 348
1 352 0 4 8 6 19 5 19 -1 20 -10 20 -318 0 -175 3 -324 6 -333 3 -9 15 -16 27
-16 11 0 18 -3 14 -6 -3 -3 -18 -3 -34 0 l-28 6 3 320 c1 209 -1 320 -8 320
-6 0 -10 -116 -10 -330 l0 -330 105 -1 c142 -2 138 -2 198 2 28 2 -9 6 -83 9
l-135 6 133 2 c86 1 131 6 127 12 -3 6 -1 10 4 10 11 0 15 -13 12 -39 -2 -7 2
-10 8 -6 6 3 11 17 11 31 l0 24 -152 1 c-84 1 -160 2 -168 3 -13 1 -15 45 -18
324 l-2 322 -30 0 -30 0 -2 -72z m285 -595 c-40 -2 -106 -2 -145 0 -40 1 -8 3
72 3 80 0 112 -2 73 -3z"/>
<path d="M4816 4444 c-3 -9 -5 -166 -4 -350 3 -310 4 -334 21 -334 16 0 17 19
17 268 0 147 -5 295 -10 330 -9 53 -8 65 5 79 18 17 33 10 19 -9 -13 -18 -6
-668 6 -668 7 0 10 125 10 350 l-1 350 -28 0 c-17 0 -31 -7 -35 -16z"/>
<path d="M5370 4438 c43 -20 44 -21 23 -31 -26 -12 -30 -25 -8 -33 8 -3 17
-14 20 -24 3 -10 15 -20 26 -23 15 -4 19 -2 15 10 -3 8 -6 17 -6 19 0 3 -4 2
-10 -1 -5 -3 -10 1 -10 9 0 8 -8 20 -17 25 -10 6 -13 11 -6 11 6 0 19 3 29 7
12 4 15 3 10 -6 -5 -7 -2 -20 6 -29 27 -34 34 -58 32 -106 -1 -35 -7 -54 -20
-63 -13 -9 -15 -16 -7 -24 14 -14 30 14 39 70 8 47 -12 130 -32 138 -8 3 -11
9 -8 15 3 5 -3 12 -15 15 -12 3 -21 9 -21 13 0 10 -47 30 -68 29 -9 0 3 -10
28 -21z"/>
<path d="M4361 4428 c-4 -13 -14 -41 -22 -63 -7 -22 -17 -44 -21 -49 -4 -6 -8
-19 -8 -30 0 -17 2 -18 10 -6 9 14 10 12 10 -17 0 -5 -7 -8 -15 -8 -8 0 -16
-5 -17 -10 0 -6 -12 -30 -25 -54 -12 -24 -23 -56 -23 -72 0 -15 -4 -29 -8 -31
-11 -4 -65 -146 -65 -168 0 -8 -3 -17 -7 -20 -14 -10 -58 -140 -49 -146 8 -5
29 35 29 57 0 5 3 9 8 9 10 0 34 69 34 98 0 12 4 22 9 22 5 0 9 -5 9 -11 0
-20 -23 -104 -30 -109 -17 -12 -31 -53 -22 -62 6 -6 21 22 41 79 17 48 36 96
42 107 6 11 7 21 2 25 -33 22 -18 91 19 91 21 0 28 14 67 136 25 75 44 149 44
165 0 15 4 26 9 23 4 -3 8 -12 8 -21 0 -9 2 -14 5 -11 10 10 -7 58 -18 51 -6
-4 -8 3 -3 21 8 32 -1 35 -13 4z m-11 -118 l-11 -25 6 25 c2 14 5 34 6 45 2
19 2 19 6 0 2 -12 -1 -32 -7 -45z m-38 -128 c-7 -2 -12 -17 -12 -32 0 -16 -5
-32 -10 -35 -7 -4 -8 3 -4 21 5 19 4 25 -5 20 -6 -4 -11 -15 -12 -24 0 -10 -3
-12 -6 -5 -2 7 8 28 24 48 16 19 30 30 32 23 2 -7 -1 -14 -7 -16z m-37 -101
c-3 -6 -11 -11 -17 -11 -6 0 -6 6 2 15 14 17 26 13 15 -4z"/>
<path d="M1666 4422 c-11 -5 7 -8 45 -8 72 1 129 -6 148 -18 7 -5 18 -3 24 3
8 8 6 11 -6 11 -9 0 -17 3 -17 8 0 10 -170 13 -194 4z"/>
<path d="M3160 4413 c-48 -32 -96 -113 -113 -187 -12 -55 -14 -169 -3 -226 8
-47 65 -162 77 -155 5 3 6 0 4 -7 -3 -8 15 -26 49 -46 68 -42 152 -56 228 -38
29 7 55 12 58 12 21 0 38 22 33 45 l-5 26 -36 -19 c-57 -30 -167 -20 -230 21
-54 35 -77 69 -103 155 -20 65 -23 125 -10 194 5 23 5 45 0 47 -7 5 -15 -37
-22 -120 -3 -29 -4 -24 -5 19 -2 66 15 136 32 130 14 -5 36 42 29 64 -4 13 -3
14 5 3 12 -17 25 1 16 23 -7 19 -20 21 -27 4 -4 -10 -6 -10 -6 0 -1 13 22 42
33 42 3 0 3 -6 0 -14 -9 -23 19 -24 63 -1 41 22 42 23 15 28 -15 3 -34 8 -42
11 -8 4 -26 -1 -40 -11z m59 -14 c-8 -5 -17 -3 -21 4 -6 9 -8 8 -8 -2 0 -8 -3
-12 -6 -8 -12 11 6 25 27 20 15 -4 17 -8 8 -14z m-89 -83 c0 -7 -7 -13 -15
-13 -17 0 -17 0 -9 21 7 18 24 12 24 -8z m-59 -101 c-8 -46 -9 -47 -10 -13 -1
34 8 73 15 66 2 -2 -1 -26 -5 -53z m8 -235 c0 -13 -1 -13 -8 0 -5 8 -11 53
-14 100 l-6 85 14 -85 c7 -47 14 -92 14 -100z m30 -28 c1 -26 5 -41 11 -37 6
3 10 0 10 -8 0 -8 7 -17 15 -21 8 -3 15 -12 15 -21 0 -8 6 -15 13 -15 8 0 7
-4 -3 -10 -11 -7 -8 -10 16 -10 16 0 34 -4 40 -9 5 -5 29 -16 54 -25 32 -12
37 -15 15 -12 -16 2 -41 8 -55 12 -23 7 -24 6 -11 -9 16 -20 6 -23 -13 -4 -7
7 -11 18 -8 26 3 10 0 12 -12 8 -15 -6 -69 19 -57 26 3 1 0 11 -7 22 -6 11
-12 16 -12 10 0 -9 -17 16 -36 54 -4 7 -2 22 4 32 7 10 10 33 7 51 -4 29 -4
30 4 8 5 -14 9 -44 10 -68z m367 -161 c-4 -6 -14 -7 -23 -4 -15 6 -14 7 3 13
22 9 29 6 20 -9z m-96 -18 c-8 -3 -18 -10 -21 -16 -4 -5 -13 -6 -20 -2 -9 6
-8 10 7 16 10 4 26 8 34 8 13 -1 13 -1 0 -6z m-97 -10 c-7 -2 -19 -2 -25 0 -7
3 -2 5 12 5 14 0 19 -2 13 -5z"/>
<path d="M2099 4293 c0 -71 -2 -219 -4 -330 -3 -195 -3 -203 16 -203 15 0 19
7 19 39 0 22 -5 41 -11 43 -7 2 -11 93 -11 291 1 158 -1 287 -4 287 -2 0 -4
-57 -5 -127z"/>
<path d="M4412 4310 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M5420 4274 c0 -56 -23 -98 -70 -127 -29 -18 -50 -22 -121 -22 -71 0
-83 -2 -72 -13 7 -7 13 -16 13 -20 0 -4 41 -6 90 -6 86 2 150 16 150 35 0 5 6
9 14 9 21 0 26 23 8 40 -14 13 -15 13 -10 -1 7 -18 -42 -54 -87 -63 -28 -6
-28 -5 10 19 22 14 43 25 48 25 4 0 7 6 7 14 0 8 14 28 30 46 24 25 30 41 29
74 0 36 -1 38 -10 17 -5 -13 -7 -33 -4 -45 5 -19 5 -19 -5 -3 -7 10 -9 29 -6
43 4 14 2 24 -4 24 -5 0 -10 -21 -10 -46z m-157 -171 c-13 -2 -33 -2 -45 0
-13 2 -3 4 22 4 25 0 35 -2 23 -4z"/>
<path d="M2470 4290 c0 -16 33 -80 42 -80 4 0 8 -7 8 -15 0 -25 17 -17 22 10
4 20 2 24 -11 20 -16 -5 -41 33 -41 62 0 7 -4 13 -10 13 -5 0 -10 -4 -10 -10z"/>
<path d="M1562 4248 c2 -6 10 -14 16 -16 7 -2 10 2 6 12 -7 18 -28 22 -22 4z"/>
<path d="M1533 4214 c-6 -17 22 -52 34 -41 3 4 -2 14 -13 21 -10 8 -15 18 -11
23 13 12 38 -28 31 -50 -5 -16 -3 -19 10 -14 10 4 14 3 11 -3 -6 -9 64 -50 86
-50 7 0 20 7 30 16 16 14 21 14 56 -10 21 -15 37 -28 36 -31 -4 -7 0 -7 20 1
20 7 9 14 -118 80 -38 20 -78 43 -88 52 -14 13 -20 13 -26 4 -6 -10 -11 -9
-21 3 -16 19 -29 19 -37 -1z m91 -34 c6 0 18 -9 26 -20 14 -20 47 -29 35 -10
-3 6 1 7 9 4 20 -8 20 -23 1 -30 -16 -6 -26 -3 -59 18 -37 24 -43 32 -37 53 4
17 6 18 9 3 2 -10 9 -18 16 -18z"/>
<path d="M1706 4091 c-3 -5 8 -14 24 -19 16 -6 45 -22 63 -36 39 -30 57 -33
57 -9 0 14 2 15 13 4 9 -10 9 -15 0 -18 -7 -2 -11 -10 -9 -16 1 -7 1 -9 -1 -4
-3 4 -9 7 -14 7 -6 0 -7 -5 -3 -11 18 -30 22 -83 7 -105 -14 -23 -14 -24 6
-24 11 0 21 -6 21 -13 0 -20 -21 -37 -44 -36 -12 0 -15 3 -8 6 14 5 17 33 3
33 -5 0 -11 -6 -14 -14 -3 -8 -31 -20 -61 -27 -53 -11 -103 -9 -220 13 -12 2
-21 -5 -27 -19 -13 -35 20 -46 151 -50 106 -4 119 -2 160 20 71 37 118 125
104 196 -13 66 -29 27 -26 -64 1 -27 0 -26 -10 9 -8 28 -7 49 1 78 10 34 10
43 -3 57 -8 9 -25 17 -37 18 -17 2 -19 -1 -10 -10 6 -6 11 -17 11 -25 0 -10
-4 -9 -17 4 -9 9 -31 23 -48 30 -16 7 -23 13 -15 14 13 0 13 1 0 10 -19 12
-47 13 -54 1z m-151 -291 c3 -5 32 -11 63 -12 51 -1 53 -2 17 -7 l-40 -5 50
-6 50 -7 -58 -1 c-32 -1 -56 2 -53 7 3 5 -12 11 -34 15 -22 3 -40 11 -40 16 0
13 37 13 45 0z m245 -8 c0 -4 -14 -13 -31 -21 -27 -12 -32 -12 -40 0 -6 11 0
15 29 20 20 3 38 7 40 8 1 0 2 -2 2 -7z"/>
<path d="M5228 4073 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5270 4069 c0 -4 6 -14 13 -21 6 -7 24 -31 38 -53 14 -22 49 -74 76
-116 27 -41 55 -73 61 -71 7 2 10 -2 6 -12 -8 -21 15 -42 49 -41 15 0 27 2 27
5 0 11 -40 70 -47 69 -19 -3 -27 2 -15 10 10 6 5 19 -20 57 -18 27 -39 61 -47
76 -7 14 -27 41 -44 60 -17 18 -31 35 -30 38 1 3 -14 5 -33 5 -19 0 -34 -3
-34 -6z m73 -26 c9 -3 17 -10 17 -15 0 -5 -6 -6 -12 -3 -7 4 -5 0 5 -8 22 -18
57 -81 57 -102 0 -8 4 -15 8 -15 4 0 15 -16 24 -35 8 -19 13 -35 9 -35 -13 0
-31 24 -31 42 0 10 -7 21 -16 25 -8 3 -13 12 -9 21 6 16 -13 45 -25 37 -4 -3
-13 9 -20 25 -7 17 -16 30 -21 30 -5 0 -9 7 -9 15 0 7 -8 19 -17 25 -16 10
-16 11 2 5 11 -4 28 -10 38 -12z m127 -183 c0 -5 -4 -10 -10 -10 -5 0 -10 5
-10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m40 -70 c0 -5 -2 -10 -4 -10 -3 0 -8
5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z"/>
<path d="M4245 4040 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M4230 4009 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M4265 4010 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M1615 3690 c-3 -5 -11 -7 -18 -4 -7 2 -29 -11 -50 -31 -34 -32 -37
-40 -37 -88 0 -45 4 -57 28 -79 33 -30 83 -53 137 -63 61 -10 111 -40 125 -74
17 -41 5 -95 -25 -119 -13 -11 -22 -22 -19 -25 3 -3 -29 -4 -71 -2 -60 2 -83
7 -105 24 -16 11 -35 19 -42 18 -7 -2 -10 -2 -5 0 11 7 9 43 -3 43 -5 0 -10
-5 -10 -11 0 -5 -4 -7 -10 -4 -21 13 1 -37 27 -64 13 -12 16 -12 24 0 7 11 9
11 9 -3 0 -20 4 -20 100 -20 l65 0 -55 -7 -55 -8 46 -1 c50 -2 114 19 102 32
-5 5 1 6 12 2 15 -4 26 3 43 28 44 65 15 191 -42 185 -11 -2 -37 1 -56 6 -30
7 -32 9 -15 15 15 6 6 10 -39 20 -41 10 -69 24 -93 46 -31 30 -34 37 -31 82 2
28 5 48 7 46 2 -2 12 3 21 11 10 9 41 18 71 21 30 3 65 8 79 11 23 5 24 6 5
13 -28 12 -113 12 -120 0z m83 -7 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0
27 -2 18 -5z m-149 -163 c3 -3 12 -13 20 -23 12 -15 12 -17 0 -12 -22 8 -36
37 -42 85 l-6 45 11 -45 c6 -25 14 -47 17 -50z m267 -134 c8 -8 14 -27 13 -43
l-1 -28 -8 28 c-5 16 -14 35 -21 43 -15 18 -2 18 17 0z m24 -101 c0 -14 -4
-25 -9 -25 -4 0 -6 11 -3 25 2 14 6 25 8 25 2 0 4 -11 4 -25z"/>
<path d="M4283 3693 c-13 -2 -23 -8 -23 -13 0 -5 -7 -6 -17 -3 -11 4 -14 3 -9
-5 4 -8 0 -12 -12 -12 -56 0 -117 -140 -109 -250 4 -71 33 -152 46 -131 6 10
10 9 14 -3 4 -9 1 -16 -5 -16 -6 0 -3 -9 7 -20 19 -21 41 -28 29 -9 -3 6 5 3
20 -6 15 -10 29 -15 32 -12 3 3 -6 13 -20 22 -14 9 -35 33 -48 53 -19 32 -22
51 -23 142 0 81 4 113 18 139 20 39 20 41 9 41 -5 0 -15 -18 -22 -41 -8 -22
-17 -42 -21 -45 -5 -3 -2 13 6 36 8 22 12 40 9 40 -3 0 8 11 24 25 17 14 34
25 38 25 5 0 0 -7 -10 -15 -11 -8 -17 -18 -13 -21 3 -3 16 4 29 16 13 12 35
27 51 32 15 6 27 15 27 21 0 9 2 9 8 1 9 -13 82 -26 82 -14 0 5 6 10 13 13 6
2 1 2 -11 0 -15 -2 -21 0 -17 7 6 10 -55 12 -102 3z m-138 -272 c-3 -2 -5 2
-6 10 -1 8 -5 3 -9 -11 l-8 -25 -1 27 c-1 14 2 34 6 44 6 15 8 13 15 -11 4
-16 5 -31 3 -34z m13 -106 c1 -13 -4 -9 -13 13 -8 18 -11 40 -7 50 5 13 8 11
13 -13 4 -16 7 -39 7 -50z"/>
<path d="M5378 3693 c-19 -3 -28 -10 -28 -23 0 -17 4 -18 26 -10 62 24 146 4
178 -42 13 -18 18 -19 31 -8 13 11 12 15 -10 36 -16 15 -38 24 -58 24 -21 0
-28 3 -19 9 27 17 -46 25 -120 14z"/>
<path d="M2078 3589 c-2 -56 -2 -171 0 -256 l4 -154 127 1 c69 1 136 -1 148
-4 22 -5 40 18 23 29 -6 3 -64 5 -130 4 -67 -1 -124 2 -128 5 -3 4 -5 45 -3
92 2 69 -1 89 -14 104 -19 21 -12 40 13 39 14 0 13 -2 -3 -8 -12 -5 36 -9 113
-10 113 -2 132 0 132 14 0 13 -18 15 -114 15 -68 0 -117 4 -121 10 -4 6 -7 50
-7 98 l-1 87 116 3 c95 2 117 5 117 17 0 12 -23 15 -134 15 l-134 0 -4 -101z
m42 87 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z m-18 -122 c0
-47 -5 -81 -13 -88 -9 -8 -10 -5 -5 14 3 14 6 56 6 94 0 38 3 67 6 63 4 -3 6
-40 6 -83z m141 -111 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z
m-146 -175 c-2 -18 -4 -4 -4 32 0 36 2 50 4 33 2 -18 2 -48 0 -65z"/>
<path d="M2366 3681 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M2620 3435 c0 -247 1 -255 20 -255 18 0 20 8 22 113 l3 112 67 0 c40
0 72 5 80 13 14 14 28 16 28 3 0 -5 -9 -13 -20 -16 -11 -3 -20 -11 -20 -16 0
-6 8 -8 19 -4 12 4 21 0 25 -11 5 -14 4 -15 -9 -4 -11 9 -15 9 -15 1 0 -11 10
-26 63 -96 33 -45 46 -58 47 -48 0 7 -14 28 -31 48 -18 20 -29 38 -26 41 7 8
73 -77 82 -106 7 -22 6 -23 -9 -11 -14 12 -16 12 -10 -3 4 -10 18 -16 36 -16
26 0 28 2 14 15 -8 8 -18 24 -21 35 -3 11 -11 20 -16 20 -5 0 -9 7 -9 15 0 8
-4 15 -9 15 -5 0 -20 17 -32 38 -13 20 -29 43 -36 50 -19 20 -16 43 7 49 11 3
17 9 14 14 -3 5 1 6 9 3 19 -7 64 42 72 78 12 54 -26 148 -60 148 -7 0 -15 5
-17 10 -2 6 -20 12 -41 15 -24 3 -36 1 -32 -5 3 -5 1 -10 -4 -10 -6 0 -11 5
-11 10 0 6 -37 10 -90 10 l-90 0 0 -255z m65 235 c3 -5 41 -10 84 -10 86 0
122 -17 143 -69 6 -14 12 -21 15 -14 2 7 -1 22 -7 33 -6 12 -9 24 -6 27 9 10
26 -46 25 -85 0 -29 -2 -33 -8 -17 -7 17 -9 16 -16 -14 -8 -37 -58 -96 -74
-87 -5 3 -22 1 -37 -4 -21 -8 -29 -8 -36 2 -6 10 -8 10 -8 -1 0 -10 -10 -12
-39 -8 -48 6 -85 -15 -78 -44 3 -11 1 -19 -4 -19 -5 0 -9 18 -9 39 0 29 4 38
15 37 23 -2 28 231 5 238 -9 2 -6 4 7 5 12 0 25 -3 28 -9z m-38 -132 c-2 -13
-4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z m293 -37 c0 -5 -9 -19 -20 -32
l-20 -24 16 33 c15 32 24 40 24 23z m-70 -161 c0 -5 -5 -10 -11 -10 -5 0 -7 5
-4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-220 -106 c0 -28 -4 -43 -10 -39 -5 3
-10 26 -10 51 0 28 4 43 10 39 6 -3 10 -26 10 -51z"/>
<path d="M3170 3682 c0 -5 7 -23 15 -38 8 -16 15 -37 15 -46 0 -9 5 -20 10
-23 6 -3 10 -16 10 -29 0 -12 4 -26 9 -31 5 -6 22 -46 36 -90 15 -44 30 -84
34 -90 6 -9 41 -97 55 -137 6 -20 60 -25 72 -7 4 8 -1 9 -16 4 -16 -5 -20 -4
-15 5 6 9 1 9 -16 -1 -22 -11 -23 -11 -11 4 8 9 11 26 7 39 -6 22 -10 33 -34
91 -6 12 -20 52 -32 90 -13 37 -26 62 -30 56 -3 -6 -16 9 -29 36 -12 25 -20
48 -17 51 3 3 11 -11 18 -30 7 -20 17 -36 21 -36 10 0 13 -7 -26 95 -26 70
-39 91 -55 93 -12 2 -21 -1 -21 -6z m44 -49 c13 -41 13 -45 1 -29 -8 11 -15
25 -15 32 0 7 -3 19 -6 28 -3 9 -4 16 -1 16 3 0 12 -21 21 -47z m73 -195 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m26 -68 c3 -11 2 -20 -3 -20
-8 0 -20 24 -20 41 0 17 17 1 23 -21z m43 -105 c20 -48 17 -57 -6 -15 -20 36
-24 50 -14 50 3 0 12 -16 20 -35z"/>
<path d="M3575 3658 c4 -24 3 -29 -4 -18 -8 12 -11 11 -17 -5 -4 -11 -13 -36
-21 -55 -21 -50 -44 -119 -49 -143 -2 -11 -8 -25 -14 -31 -15 -15 -13 -49 3
-40 7 4 9 3 4 -2 -5 -5 -14 -9 -20 -9 -7 0 -11 -14 -10 -35 2 -25 0 -30 -5
-16 -7 15 -10 11 -20 -21 -16 -53 -14 -65 4 -28 l14 30 -5 -35 c-6 -42 -1 -31
45 89 17 46 29 89 26 97 -3 8 -1 24 5 36 8 20 9 19 3 -7 -3 -16 -2 -27 2 -22
15 15 33 82 28 101 -4 12 -2 16 5 12 6 -4 16 5 22 21 7 15 18 42 25 60 19 43
18 53 -7 53 -18 0 -20 -4 -14 -32z m-13 -70 c-7 -7 -12 -8 -12 -2 0 6 3 14 7
17 3 4 9 5 12 2 2 -3 -1 -11 -7 -17z m-35 -80 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z"/>
<path d="M3830 3435 c0 -248 1 -255 20 -255 19 0 20 7 20 255 0 248 -1 255
-20 255 -19 0 -20 -7 -20 -255z m30 0 c0 -135 -3 -245 -7 -245 -5 0 -7 110 -5
245 1 135 5 245 7 245 3 0 5 -110 5 -245z"/>
<path d="M4742 3457 c-3 -176 -1 -229 8 -221 7 7 11 35 8 72 -1 33 0 58 5 55
14 -9 8 -134 -8 -149 -8 -9 -15 -20 -15 -25 0 -5 66 -9 155 -9 131 0 155 2
155 15 0 13 -20 15 -127 13 -90 -1 -115 -3 -83 -8 42 -7 40 -7 -20 -7 -36 1
-58 2 -49 4 14 3 15 18 12 99 -7 149 -17 139 134 137 75 -1 120 1 108 6 l-20
8 20 6 c11 3 -37 5 -107 4 -145 -2 -136 -8 -137 93 0 87 -4 91 -11 10 -7 -73
-7 -72 -6 28 1 62 -3 102 -9 102 -6 0 -11 -86 -13 -233z m131 -14 c-7 -2 -21
-2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m80 0 c-7 -2 -21 -2 -30 0 -10 3
-4 5 12 5 17 0 24 -2 18 -5z m-183 -25 c0 -18 -2 -20 -9 -8 -6 8 -7 18 -5 22
9 14 14 9 14 -14z"/>
<path d="M4781 3668 c0 -13 2 -18 5 -13 2 6 54 9 134 7 112 -3 130 -1 130 12
0 14 -20 16 -135 16 -134 0 -135 0 -134 -22z"/>
<path d="M1745 3671 c-19 -8 -19 -8 4 -21 21 -11 23 -10 18 4 -4 9 -2 16 3 16
10 0 14 -13 11 -36 -2 -16 26 -37 39 -29 16 10 12 22 -17 50 -30 27 -29 27
-58 16z"/>
<path d="M4428 3672 c-15 -6 -12 -11 18 -36 36 -28 36 -28 29 -5 l-7 24 18
-24 c12 -17 14 -26 6 -29 -21 -7 -13 -20 8 -15 11 3 20 10 20 15 0 9 -64 79
-71 77 -2 0 -12 -4 -21 -7z"/>
<path d="M5303 3651 c-15 -19 -19 -32 -12 -42 5 -8 8 -32 8 -54 l-1 -40 -9 44
c-11 55 -19 64 -19 22 0 -67 63 -143 105 -127 8 3 15 0 17 -6 2 -6 28 -17 58
-25 30 -8 65 -21 78 -29 47 -31 56 -106 17 -152 -18 -22 -22 -32 -13 -36 7 -3
2 -3 -11 0 -13 3 -27 1 -30 -5 -9 -14 -119 6 -143 26 -9 8 -21 12 -26 9 -5 -3
-18 1 -28 10 -18 14 -18 15 4 4 25 -12 27 -8 10 19 -6 11 -19 16 -29 14 -36
-10 12 -71 76 -97 82 -35 226 -2 243 55 3 8 -7 3 -22 -10 -30 -28 -34 -17 -6
17 11 13 24 21 29 18 13 -8 20 40 11 81 -9 38 -36 73 -57 73 -7 0 -13 5 -13
11 0 7 -12 8 -32 4 -25 -5 -29 -4 -18 4 13 9 13 11 -1 11 -34 0 -120 32 -145
54 -31 26 -40 89 -18 121 15 21 19 55 7 54 -5 0 -18 -13 -30 -28z m27 -156 c7
-9 8 -15 2 -15 -5 0 -12 7 -16 15 -3 8 -4 15 -2 15 2 0 9 -7 16 -15z m259
-144 c-1 -27 -3 -30 -6 -11 -3 14 -9 29 -14 35 -14 14 -11 25 6 19 10 -4 15
-18 14 -43z m6 -51 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8
-4 11 -10z m-249 -97 c-7 -7 -26 7 -26 19 0 6 6 6 15 -2 9 -7 13 -15 11 -17z
m87 -20 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z"/>
<path d="M1748 3433 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4496 3264 c-4 -9 -9 -14 -12 -11 -6 5 -41 -21 -74 -56 -14 -14 -29
-17 -66 -14 l-49 3 53 2 c28 1 52 6 52 12 0 5 -13 7 -30 5 -16 -3 -44 -7 -60
-10 -22 -3 -29 -1 -24 7 5 8 0 9 -16 5 -13 -3 -32 -2 -42 4 -11 6 -18 6 -18 0
0 -14 88 -41 135 -41 51 0 107 18 99 32 -4 6 1 8 15 3 17 -5 20 -3 14 11 -4
11 -3 15 5 10 10 -6 42 29 42 46 0 15 -18 8 -24 -8z"/>
<path d="M0 50 l0 -50 3600 0 3600 0 0 50 0 50 -3600 0 -3600 0 0 -50z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
